import React, { useState } from "react";
import {
  Box,
  IconButton,
  Collapse,
  Typography,
  List,
  ListItem,
  Skeleton,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { StyledBox } from "../../styled/box/styledBox.component";
import { Cart } from "../../../models/cart/cart.model";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { EquipmentMasterBase } from "../../../models/equipment/equipmentMasterBase.model";
import { GetEquipmentsByCartId } from "../../../../service/equipmentService";
import EquipmentSimpleTreeView from "../trees/equipmentSimpleTreeView.component";
import CartPopupActions from "../actions/cartPopupActions.component";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface CartsListProps {
  data: Cart[];
  loading: boolean;
  noDataFound: boolean;
  onDeleteSuccess: (id: string) => void;
  onUpdateSuccess: () => void;
  onUpdateFailed: () => void;
  onEditCart: (cart: Cart) => void;
}

export const CartsList: React.FC<CartsListProps> = ({
  data,
  loading,
  noDataFound,
  onDeleteSuccess,
  onUpdateSuccess,
  onUpdateFailed,
  onEditCart,
}) => {
  const [expandedCartId, setExpandedCartId] = useState<string | null>(null);
  const [equipmentList, setEquipmentList] = useState<
    Record<string, EquipmentMasterBase[]>
  >({});
  const [loadingEquipments, setLoadingEquipments] = useState<
    Record<string, boolean>
  >({});
  const [error, setError] = useState<string | null>(null);

  const handleExpandClick = async (cartId: string) => {
    if (expandedCartId === cartId) {
      setExpandedCartId(null);
      return;
    }

    setError(null);

    if (equipmentList[cartId]) {
      setExpandedCartId(cartId);
      return;
    }

    setLoadingEquipments((prev) => ({ ...prev, [cartId]: true }));
    setExpandedCartId(cartId);

    try {
      const result = await GetEquipmentsByCartId(cartId);

      if (result.length === 0) {
        setEquipmentList((prev) => ({ ...prev, [cartId]: [] }));
      } else {
        setEquipmentList((prev) => ({ ...prev, [cartId]: result }));
      }
    } catch {
      setError("Error fetching equipment");
    } finally {
      setLoadingEquipments((prev) => ({ ...prev, [cartId]: false }));
    }
  };

  const renderExpandButton = (cart: Cart) => (
    <IconButton
      size="small"
      onClick={() => handleExpandClick(cart.id)}
      aria-expanded={expandedCartId === cart.id}
      aria-label={expandedCartId === cart.id ? "Collapse" : "Expand"}
    >
      {loadingEquipments[cart.id] ? (
        <CircularProgress size={24} />
      ) : (
        <>
          {expandedCartId === cart.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          <Typography
            variant="body2"
            sx={{ fontSize: "0.75rem", marginLeft: 1 }}
          >
            {expandedCartId === cart.id ? "Hide equipments" : "Show equipments"}
          </Typography>
        </>
      )}
    </IconButton>
  );

  return (
    <Box>
      {loading ? (
        <List>
          {Array.from({ length: 3 }).map((_, index) => (
            <StyledBox
              key={index}
              sx={{
                width: "100%",
                justifyContent: "flex-end",
                marginBottom: "14px",
              }}
            >
              <ListItem sx={{ padding: 3.2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Skeleton variant="text" height={25} width={100} />
                  <Skeleton
                    variant="text"
                    height={16}
                    width={180}
                    sx={{ mt: 2 }}
                  />
                  <Skeleton
                    variant="text"
                    height={16}
                    width={300}
                    sx={{ mt: 0.5 }}
                  />
                </Box>
              </ListItem>
            </StyledBox>
          ))}
        </List>
      ) : noDataFound ? (
        <StyledBox
          sx={{
            width: "100%",
            justifyContent: "flex-end",
            marginBottom: "16px",
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ textAlign: "center" }}
          >
            No cart found.
          </Typography>
        </StyledBox>
      ) : (
        data.map((cart) => (
          <StyledBox
            key={cart.id}
            sx={{
              width: "100%",
              height: "100%",
              justifyContent: "flex-end",
              marginBottom: "14px",
            }}
          >
            <Box paddingX={2} paddingY={1}>
              <CartPopupActions
                cart={cart}
                onDeleteSuccess={() => onDeleteSuccess(cart.id)}
                onUpdateSuccess={onUpdateSuccess}
                onUpdateFailed={onUpdateFailed}
                onEdit={() => onEditCart(cart)}
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "center", paddingX: 10 }}
            >
              {renderExpandButton(cart)}
            </Box>
            <Collapse in={expandedCartId === cart.id}>
              <Box sx={{ paddingTop: 1, paddingX: 3, paddingBottom: 3 }}>
                {error ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#ffe6e6",
                      padding: "8px 16px",
                      "&:hover": {
                        borderColor: "#d32f2f",
                      },
                      "&.Mui-focused": {
                        borderColor: "#d32f2f",
                        boxShadow: "0 0 4px rgba(211, 47, 47, 0.5)",
                      },
                    }}
                  >
                    <ErrorOutlineIcon
                      sx={{ color: "#d32f2f", marginRight: 1 }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "center",
                        color: "#d32f2f",
                      }}
                    >
                      {error}
                    </Typography>
                  </Box>
                ) : (
                  <EquipmentSimpleTreeView
                    data={equipmentList[cart.id] || []}
                    loading={loadingEquipments[cart.id] || false}
                  />
                )}
              </Box>
            </Collapse>
          </StyledBox>
        ))
      )}
    </Box>
  );
};
