import React, { useState } from 'react';
import { Badge, IconButton, Menu, MenuItem, Box, Typography } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';

interface NotificationBellProps {
    notifications: string[];
}

const NotificationBell: React.FC<NotificationBellProps> = ({ notifications }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <IconButton
                aria-label="notifications"
                onClick={handleClick}
                color="inherit"
                sx={{
                    opacity: notifications.length > 0 ? 1 : 0.5, // Controla a opacidade aqui
                }}
            >
                <Badge
                    badgeContent={notifications.length}
                    color="error"
                >
                    <NotificationsIcon />
                </Badge>
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: { width: 250 },
                }}
            >
                {notifications.length > 0 ? (
                    notifications.map((notification, index) => (
                        <MenuItem key={index} onClick={handleClose}>
                            <Typography variant="body2">{notification}</Typography>
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem onClick={handleClose}>
                        <Typography variant="body2" color="text.secondary">
                            No new notifications
                        </Typography>
                    </MenuItem>
                )}
            </Menu>
        </Box>
    );
};

export default NotificationBell;
