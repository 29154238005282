import React, { createContext, useContext, useState } from "react";
import { EquipmentMasterBase } from "../models/equipment/equipmentMasterBase.model";
import { GetEquipmentHierarchy } from "../../service/equipmentService";
import { useSnackbarContext } from "../components/styled/alert/snackBarAlert.component";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
} from "@mui/material";

interface EquipmentContextType {
  equipmentList: EquipmentMasterBase[];
  setEquipmentList: (equipments: EquipmentMasterBase[]) => void;
  addEquipment: (equipment: EquipmentMasterBase) => void;
  removeEquipment: (equipmentIds: number | number[]) => void;
  toggleAddEquipment: () => void;
  mergeHierarchy: (
    equipments: EquipmentMasterBase[],
    newEquipments: EquipmentMasterBase[]
  ) => EquipmentMasterBase[];
}

const EquipmentContext = createContext<EquipmentContextType | undefined>(
  undefined
);

export const EquipmentProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [equipmentList, setEquipmentListState] = useState<
    EquipmentMasterBase[]
  >([]);
  const [isAddEquipmentEnabled, setIsAddEquipmentEnabled] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [pendingEquipment, setPendingEquipment] =
    useState<EquipmentMasterBase | null>(null);
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbarContext();

  const setEquipmentList = (equipments: EquipmentMasterBase[]) => {
    setEquipmentListState(equipments);
  };

  const mergeHierarchy = (
    currentList: EquipmentMasterBase[],
    newItems: EquipmentMasterBase[]
  ): EquipmentMasterBase[] => {
    const updatedList = [...currentList];
    newItems.forEach((newItem) => {
      const existingIndex = updatedList.findIndex(
        (item) => item.equipmentId === newItem.equipmentId
      );
      if (existingIndex !== -1) {
        updatedList[existingIndex].children = mergeHierarchy(
          updatedList[existingIndex].children || [],
          newItem.children || []
        );
      } else {
        updatedList.push({
          ...newItem,
          children: mergeHierarchy([], newItem.children || []),
        });
      }
    });
    return updatedList;
  };

  const isItemPresent = (
    item: EquipmentMasterBase,
    list: EquipmentMasterBase[]
  ): boolean => {
    const foundItem = list.find(
      (existingItem) => existingItem.equipmentId === item.equipmentId
    );
    if (!foundItem) return false;
    if (item.children && item.children.length > 0) {
      return checkHierarchyRecursively(item.children, foundItem.children || []);
    }
    return true;
  };

  const checkHierarchyRecursively = (
    items: EquipmentMasterBase[],
    list: EquipmentMasterBase[]
  ): boolean => {
    if (!items || items.length === 0) return true;
    return items.every((item) => isItemPresent(item, list));
  };

  const addEquipmentToState = async (equipment: EquipmentMasterBase) => {
    const hierarchy = await GetEquipmentHierarchy(equipment.equipmentId, false);
    const allItemsAlreadyAdded = hierarchy.every((newItem) =>
      isItemPresent(newItem, equipmentList)
    );

    if (allItemsAlreadyAdded) {
      openSnackbar("All equipments have already been added.", "info");
      return;
    }

    setEquipmentListState((prevList) => mergeHierarchy(prevList, hierarchy));
    openSnackbar(`${equipment.name} was added to your cart.`, "success");
  };

  const addEquipment = async (equipment: EquipmentMasterBase) => {
    if (!isAddEquipmentEnabled) return;
    if (
      equipment.hcsAbbreviation !== "SY" &&
      equipment.hcsAbbreviation !== "UN"
    ) {
      openSnackbar(`You can't add a part. Please select an equipment.`, "info");

      return;
    }
    if (equipment.level === 1 || equipment.level === 2) {
      setPendingEquipment(equipment);
      setConfirmationOpen(true);
      return;
    }
    await addEquipmentToState(equipment);
  };

  const handleConfirmAdd = async () => {
    if (pendingEquipment) {
      setLoading(true);
      await addEquipmentToState(pendingEquipment);
      setPendingEquipment(null);
      setLoading(false);
    }
    setConfirmationOpen(false);
  };

  const handleCancelAdd = () => {
    setPendingEquipment(null);
    setConfirmationOpen(false);
  };

  const removeEquipment = (equipmentIds: number | number[]) => {
    const idsToRemove = Array.isArray(equipmentIds)
      ? equipmentIds
      : [equipmentIds];
    const recursivelyRemoveItems = (
      items: EquipmentMasterBase[],
      idsToRemove: number[]
    ): EquipmentMasterBase[] => {
      return items
        .map((item) => {
          const filteredChildren = recursivelyRemoveItems(
            item.children || [],
            idsToRemove
          );
          const shouldRemoveItem = idsToRemove.includes(item.equipmentId);
          return shouldRemoveItem ||
            (filteredChildren.length === 0 && item.children?.length > 0)
            ? null
            : { ...item, children: filteredChildren };
        })
        .filter(Boolean) as EquipmentMasterBase[];
    };

    const removedEquipmentNames = equipmentList
      .filter((e) => idsToRemove.includes(e.equipmentId))
      .map((e) => e.name)
      .join(", ");

    setEquipmentListState((prevList) =>
      recursivelyRemoveItems(prevList, idsToRemove)
    );

    if (removedEquipmentNames) {
      openSnackbar(
        `${removedEquipmentNames} was removed from your cart.`,
        "info"
      );
    }
  };

  const toggleAddEquipment = () => {
    setEquipmentList([]);
    setIsAddEquipmentEnabled((prevState) => !prevState);
  };

  return (
    <EquipmentContext.Provider
      value={{
        equipmentList,
        setEquipmentList,
        addEquipment,
        removeEquipment,
        toggleAddEquipment,
        mergeHierarchy,
      }}
    >
      {children}
      <Dialog
        open={confirmationOpen}
        onClose={handleCancelAdd}
        aria-labelledby="confirm-add-dialog-title"
        aria-describedby="confirm-add-dialog-description"
      >
        <DialogTitle id="confirm-add-dialog-title">
          {"Add equipment hierarchy?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-add-dialog-description" fontSize={12}>
            This equipment is a higher-level item. Adding it will include its
            entire related hierarchy. Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!loading ? (
            <Button
              onClick={handleCancelAdd}
              disabled={loading}
              color="secondary"
            >
              Cancel
            </Button>
          ) : (
            <></>
          )}
          <Button onClick={handleConfirmAdd} color="success" disabled={loading}>
            {loading ? "Confirming..." : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </EquipmentContext.Provider>
  );
};

export const useEquipmentContext = (): EquipmentContextType => {
  const context = useContext(EquipmentContext);
  if (!context) {
    throw new Error(
      "useEquipmentContext must be used within an EquipmentProvider"
    );
  }
  return context;
};
