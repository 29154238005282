import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import { DeleteCartAsync } from "../../../../../service/cartService";
import { red } from "@mui/material/colors";

interface DeleteCartButtonProps {
  cartId: string;
  onDeleteSuccess: () => void;
}

export const DeleteCartButton: React.FC<DeleteCartButtonProps> = ({
  cartId,
  onDeleteSuccess,
}) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await DeleteCartAsync(cartId);
      onDeleteSuccess();
    } catch (error) {
      console.error("Failed to delete cart", error);
    } finally {
      setLoading(false);
    }

    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const buttonSx = {
    paddingY: "4px",
    paddingX: "8px",
    minWidth: "auto",
    bgcolor: red[500],
    "&:hover": {
      bgcolor: red[700],
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Box sx={{ m: 1, position: "relative", display: "inline-flex" }}>
      <Tooltip title="Delete" arrow placement="left">
        <span>
          <Button
            size="small"
            color="error"
            variant="contained"
            onClick={handleClickOpen}
            sx={buttonSx}
          >
            <DeleteIcon fontSize="inherit" />
          </Button>
        </span>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete this cart?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" fontSize={12}>
            Your downloads will also be deleted. This action is irreversible,
            and the items will be permanently removed.
          </DialogContentText>
          {loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <CircularProgress />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {!loading ? (
            <Button onClick={handleClose} disabled={loading}>
              Cancel
            </Button>
          ) : (
            <></>
          )}
          <Button onClick={handleConfirm} color="error" disabled={loading}>
            {loading ? "Deleting..." : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
