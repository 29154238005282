import * as React from "react";
import Button from "@mui/material/Button";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { RestorePage } from "@mui/icons-material";
import { ExportAsync } from "../../../../../service/exportService";
import { useAppContext } from "../../../../context/app.context";
import { useSnackbarContext } from "../../../styled/alert/snackBarAlert.component";

interface ExportCartButtonProps {
  cartId: string;
  disabled?: boolean;
}

export const ExportCartButton: React.FC<ExportCartButtonProps> = ({
  cartId,
  disabled = false,
}) => {
  const [loading, setLoading] = React.useState(false);
  const { openSnackbar } = useSnackbarContext();
  const context = useAppContext();

  const handleButtonClick = async () => {
    if (loading || disabled) return;

    setLoading(true);
    try {
      await ExportAsync(context, cartId);
      openSnackbar(
        "Export requested successfully! The export will be available for download soon.",
        "success"
      );
    } catch {
      openSnackbar("Error exporting data.", "error");
    } finally {
      setLoading(false);
    }
  };

  const buttonStyles = {
    paddingY: "4px",
    paddingX: "8px",
    minWidth: "auto",
    bgcolor: blue[500],
    "&:hover": {
      bgcolor: blue[700],
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Box sx={{ m: 1, position: "relative", display: "inline-flex" }}>
      <Tooltip title="Export" arrow placement="left">
        <span>
          <Button
            variant="contained"
            size="small"
            sx={buttonStyles}
            disabled={loading || disabled}
            onClick={handleButtonClick}
          >
            <RestorePage fontSize="inherit" />
          </Button>
        </span>
      </Tooltip>
      {loading && (
        <CircularProgress
          size={18}
          sx={{
            color: grey[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-9px",
            marginLeft: "-9px",
          }}
        />
      )}
    </Box>
  );
};
