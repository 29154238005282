import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { ArrowBack, Save } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { theme } from "../../../../styles/theme";
import { StyledBox } from "../../styled/box/styledBox.component";
import { StyledHeader } from "../../styled/header/styledHeader.component";
import { SaveCart } from "../../../models/cart/actions/saveCart.context.model";
import { SaveCartAsync } from "../../../../service/cartService";
import { useAppContext } from "../../../context/app.context";
import { useSnackbarContext } from "../../styled/alert/snackBarAlert.component";
import { useEquipmentContext } from "../../../context/equipment.context";
import EquipmentTreeView from "../trees/equipmentTreeView.component";
import { EquipmentMasterBase } from "../../../models/equipment/equipmentMasterBase.model";
import { isValidName } from "../../../../utils/validators/inputValidator";

interface AddNewCartViewProps {
  onBack: () => void;
}

const AddNewCartView: React.FC<AddNewCartViewProps> = ({ onBack }) => {
  const { t } = useTranslation();
  const { equipmentList, removeEquipment, toggleAddEquipment } =
    useEquipmentContext();
  const { openSnackbar } = useSnackbarContext();
  const context = useAppContext();
  const [loading, setLoading] = useState(false);
  const [nameField, setNameField] = useState("");
  const [nameErrorText, setNameErrorText] = useState("");

  useEffect(() => {
    toggleAddEquipment();

    return () => {
      toggleAddEquipment();
    };
  }, []);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isValidName(value)) {
      setNameField(value);
      setNameErrorText("");
    }
  };

  const collectAllEquipmentIds = (items: EquipmentMasterBase[]): number[] =>
    items.reduce<number[]>((allIds, item) => {
      allIds.push(item.equipmentId);
      if (item.children) {
        allIds.push(...collectAllEquipmentIds(item.children));
      }
      return allIds;
    }, []);

  const handleSubmit = async () => {
    if (!nameField.trim()) {
      setNameErrorText(t("component.cart.field_is_required"));
      return;
    }

    const allEquipmentIds = collectAllEquipmentIds(equipmentList);

    const saveCartRequest: SaveCart = {
      userId: context.user.homeAccountId,
      name: nameField.trim(),
      equipments: allEquipmentIds,
    };

    setLoading(true);
    try {
      await SaveCartAsync(context, saveCartRequest);
      openSnackbar("Cart saved successfully.", "success");
      resetForm();
      handleBack();
    } catch {
      openSnackbar("Error trying to save new cart.", "error");
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setNameField("");
    setNameErrorText("");
  };

  const handleBack = () => {
    onBack();
  };

  return (
    <Box>
      <ThemeProvider theme={theme}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Box sx={{ position: "relative", width: "90%", display: "flex" }}>
            <StyledHeader label={t("component.cart.cart_details")} />
          </Box>
          <StyledBox sx={{ position: "relative", width: "70%" }}>
            <Box sx={{ padding: "12px" }}>
              <TextField
                autoFocus
                margin="dense"
                placeholder="Name"
                error={!!nameErrorText}
                fullWidth
                variant="outlined"
                value={nameField}
                onChange={handleNameChange}
                disabled={loading}
                autoComplete="off"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: !!nameErrorText ? "#ffe6e6" : "#eef2f5",
                    "& fieldset": {
                      borderColor: !!nameErrorText ? "#d32f2f" : "#c4c4c4",
                    },
                    "&:hover fieldset": {
                      borderColor: !!nameErrorText ? "#d32f2f" : "#1976d2",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: !!nameErrorText ? "#d32f2f" : "#1976d2",
                      boxShadow: !!nameErrorText
                        ? "0 0 4px rgba(211, 47, 47, 0.5)"
                        : "0 0 4px rgba(25, 118, 210, 0.25)",
                    },
                  },
                }}
                slotProps={{
                  input: {
                    sx: {
                      color: "#000",
                      "&::placeholder": { color: "#4a4a4a", opacity: 1 },
                      caretColor: "#000",
                    },
                  },
                }}
              />
              {nameErrorText && (
                <Alert
                  severity="error"
                  sx={{
                    marginTop: "8px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "0.875rem",
                    color: "#b71c1c",
                    backgroundColor: "#ffebee",
                  }}
                >
                  {nameErrorText}
                </Alert>
              )}
            </Box>

            <Box sx={{ padding: "12px" }}>
              <EquipmentTreeView
                data={equipmentList}
                onRemove={removeEquipment}
                loading={false}
                isAdding={true}
              />
            </Box>
            <Box
              sx={{
                paddingX: "12px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={handleBack}
                disabled={loading}
                startIcon={<ArrowBack />}
                sx={{
                  backgroundColor: "#b0b0b0",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#9e9e9e",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#f5f5f5",
                    color: "#b0b0b0",
                  },
                }}
              >
                Back
              </Button>

              <Box sx={{ position: "relative" }}>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ bgcolor: blue[500], "&:hover": { bgcolor: blue[700] } }}
                  disabled={loading}
                  startIcon={<Save />}
                  onClick={handleSubmit}
                >
                  {t("component.cart.save")}
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: blue[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Box>
          </StyledBox>
        </form>
      </ThemeProvider>
    </Box>
  );
};

export default AddNewCartView;
