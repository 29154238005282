import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  Typography,
  IconButton,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { EquipmentMasterBase } from "../../models/equipment/equipmentMasterBase.model";
import { useEquipmentContext } from "../../context/equipment.context";
import {
  GetEquipmentChildren,
  GetEquipmentPartsById,
} from "../../../service/equipmentService";
import InfoIcon from "@mui/icons-material/Info";
import PartsTreeModal from "../cart/modals/partsTreeModal.component";

interface EquipmentsTreeBoxProps {
  equipments: EquipmentMasterBase[];
  loading: boolean;
}

const EquipmentsTreeBox: React.FC<EquipmentsTreeBoxProps> = ({
  equipments = [],
  loading,
}) => {
  const { addEquipment, mergeHierarchy } = useEquipmentContext();
  const [updatedEquipments, setUpdatedEquipments] =
    useState<EquipmentMasterBase[]>(equipments);
  const [expandedItems, setExpandedItems] = useState<Set<number>>(new Set());
  const [loadingItems, setLoadingItems] = useState<Set<number>>(new Set());
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoadingParts, setIsLoadingParts] = useState(false);
  const [modalData, setModalData] = useState<EquipmentMasterBase[]>([]);

  useEffect(() => {
    setUpdatedEquipments(equipments);
  }, [equipments]);

  const handleIconClick = useCallback(
    async (e: React.MouseEvent, equipmentId: number) => {
      e.stopPropagation();
      setIsLoadingParts(true);
      setModalOpen(true);

      try {
        const partsData = await GetEquipmentPartsById(equipmentId);
        setModalData(partsData || []);
      } catch (error) {
        console.error("Error fetching equipment parts:", error);
        setModalData([]);
      } finally {
        setIsLoadingParts(false);
      }
    },
    []
  );

  const handleExpandClick = useCallback(
    async (event: React.MouseEvent, equipmentId: number) => {
      event.stopPropagation();

      if (loadingItems.has(equipmentId)) return;

      if (!expandedItems.has(equipmentId)) {
        setLoadingItems((prev) => new Set(prev).add(equipmentId));
        try {
          const children = await GetEquipmentChildren(equipmentId);
          if (children && children.length > 0) {
            const mergedEquipments = mergeHierarchy(
              updatedEquipments,
              children
            );
            setUpdatedEquipments(mergedEquipments);
          }
          setExpandedItems((prev) => new Set(prev).add(equipmentId));
        } catch (error) {
          console.error("Error fetching children:", error);
        } finally {
          setLoadingItems((prev) => {
            const newSet = new Set(prev);
            newSet.delete(equipmentId);
            return newSet;
          });
        }
      } else {
        setExpandedItems((prev) => {
          const newSet = new Set(prev);
          newSet.delete(equipmentId);
          return newSet;
        });
      }
    },
    [expandedItems, loadingItems, mergeHierarchy, updatedEquipments]
  );

  const RenderTreeItems: React.FC<{ equipment: EquipmentMasterBase }> = ({
    equipment,
  }) => {
    const itemId = String(equipment.equipmentId);
    const isLoading = loadingItems.has(equipment.equipmentId);

    return (
      <TreeItem
        key={itemId}
        itemId={itemId}
        label={
          <Box
            sx={{ display: "flex", alignItems: "center", userSelect: "none" }}
          >
            <Box>
              <Typography variant="body1">{equipment.name}</Typography>
              <Typography variant="body2" color="textSecondary">
                {`${equipment.hcsAbbreviation} - [${equipment.equipCode}]`}
              </Typography>
            </Box>
            {equipment.level === 3 ? (
              <IconButton
                size="small"
                onClick={(e) => handleIconClick(e, equipment.equipmentId)}
                sx={{
                  marginLeft: 2,
                  color: "#fff",
                  backgroundColor: "#777",
                  "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                }}
              >
                <Tooltip
                  title="View parts"
                  placement="right"
                  arrow
                  slotProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: "#555",
                        color: "#fff",
                        fontSize: "0.875rem",
                        padding: "8px 12px",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "#555",
                      },
                    },
                  }}
                >
                  <InfoIcon />
                </Tooltip>
              </IconButton>
            ) : (
              isLoading && (
                <Box sx={{ marginLeft: 3 }}>
                  <CircularProgress size={22} color="secondary" />
                </Box>
              )
            )}
          </Box>
        }
        onDoubleClick={(e) => {
          e.stopPropagation();
          addEquipment(equipment);
        }}
        onClick={
          equipment.level !== 3
            ? (e) => handleExpandClick(e, equipment.equipmentId)
            : undefined
        }
      >
        {equipment.children &&
        equipment.children.length > 0 &&
        equipment.level !== 3
          ? equipment.children.map((child) => (
              <RenderTreeItems key={child.equipmentId} equipment={child} />
            ))
          : equipment.level !== 3 &&
            !isLoading && (
              <TreeItem
                itemId={`${itemId}-placeholder`}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      paddingLeft: 2,
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      No parts found.
                    </Typography>
                  </Box>
                }
              />
            )}
      </TreeItem>
    );
  };

  const renderLoadingState = () => (
    <List>
      {Array.from({ length: 5 }).map((_, index) => (
        <ListItem key={index} sx={{ marginBottom: 3 }}>
          <Box display="flex" flexDirection="column" width="100%">
            <Skeleton variant="text" width="80%" height={40} />
            <Skeleton variant="text" width="40%" height={30} />
          </Box>
        </ListItem>
      ))}
    </List>
  );

  const renderEmptyState = () => (
    <List>
      <ListItem>
        <Typography variant="body2" color="text.secondary">
          No equipment found.
        </Typography>
      </ListItem>
    </List>
  );

  const renderEquipmentTree = () => (
    <List>
      {updatedEquipments.map((equipment) => (
        <ListItem key={equipment.equipCode} sx={{ cursor: "pointer" }}>
          <SimpleTreeView>
            <RenderTreeItems equipment={equipment} />
          </SimpleTreeView>
        </ListItem>
      ))}
      <PartsTreeModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        data={modalData}
        loading={isLoadingParts}
      />
    </List>
  );

  return loading
    ? renderLoadingState()
    : equipments.length === 0
    ? renderEmptyState()
    : renderEquipmentTree();
};

export default EquipmentsTreeBox;
