import axios from "axios";
import { IDownloadExport } from "../shared/models/cart/actions/exportCart.model";
import { API_KEY, API_URL } from "../shared/constants/cartsConstants";
import { IContext } from "../shared/models/context/user.context.model";

interface IDownloadExportRoot {
  data?: IDownloadExport[];
}

const createConfig = () => ({
  headers: {
    "x-functions-key": API_KEY,
  },
});

const handleError = (error: unknown): never => {
  const errorMessage = axios.isAxiosError(error)
    ? `Failed to fetch data: ${error.message}`
    : "Failed to fetch data due to an unexpected error.";
  throw new Error(errorMessage);
};

export const GetAllDownloadsAsync = async (
  context: IContext
): Promise<IDownloadExport[]> => {
  try {
    const {
      user: { homeAccountId },
    } = context;
    const response = await axios.get<IDownloadExportRoot>(
      `${API_URL}/${homeAccountId}/downloads`,
      createConfig()
    );
    return response.data?.data ?? [];
  } catch (error) {
    handleError(error);
  }
  return [];
};
