import axios from "axios";
import { API_KEY, API_URL } from "../shared/constants/cartsConstants";
import { Cart } from "../shared/models/cart/cart.model";
import { SaveCart } from "../shared/models/cart/actions/saveCart.context.model";
import { UpdateCart } from "../shared/models/cart/actions/updateCart.context";
import { IContext } from "../shared/models/context/user.context.model";

interface ICartRoot {
  data: Cart[];
}

const createConfig = () => ({
  headers: {
    "x-functions-key": API_KEY,
  },
});

const handleError = (error: unknown, customMessage: string) => {
  const message = axios.isAxiosError(error)
    ? `${customMessage}: ${error.message}`
    : "Failed to fetch data due to an unexpected error.";
  throw new Error(message);
};

export const DeleteCartAsync = async (cartId: string): Promise<void> => {
  try {
    await axios.delete(`${API_URL}/${cartId}`, createConfig());
  } catch (error) {
    handleError(error, `Failed to delete cart with ID ${cartId}`);
  }
};

export const GetAllAsync = async (context: IContext): Promise<Cart[]> => {
  const userId = context.user.homeAccountId;

  try {
    const response = await axios.get<ICartRoot>(
      `${API_URL}?userId=${userId}`,
      createConfig()
    );
    return response.data?.data ?? [];
  } catch (error) {
    handleError(error, "Failed to fetch data");
    return [];
  }
};

export const SaveCartAsync = async (
  context: IContext,
  data: SaveCart
): Promise<void> => {
  try {
    data.userId = context.user.homeAccountId;
    await axios.post(API_URL, data, createConfig());
  } catch (error) {
    handleError(error, "Failed to save cart");
  }
};

export const UpdateCartAsync = async (
  context: IContext,
  data: UpdateCart
): Promise<void> => {
  try {
    data.userId = context.user.homeAccountId;
    await axios.put(`${API_URL}/${data.cartId}`, data, createConfig());
  } catch (error) {
    handleError(error, "Failed to update cart");
  }
};
