import axios from 'axios';
import { API_KEY, API_URL } from '../shared/constants/cartsConstants';
import { IContext } from '../shared/models/context/user.context.model';

const createConfig = () => ({
    headers: {
        'x-functions-key': API_KEY,
    },
});

const handleError = (error: unknown, cartId: string) => {
    if (axios.isAxiosError(error)) {
        throw new Error(`Failed to export cart with ID ${cartId}: ${error.response?.data}`);
    }
    throw new Error('Failed to export cart due to an unexpected error.');
};

export const ExportAsync = async (context: IContext, cartId: string): Promise<void> => {
    try {
        const data = {
            cartId,
            userId: context.user.homeAccountId,
        };

        const response = await axios.post(`${API_URL}/export`, data, createConfig());

        if (response.status !== 200) {
            throw new Error(`Error: ${response.statusText}`);
        }
    } catch (error) {
        handleError(error, cartId);
    }
};
