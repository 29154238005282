import axios from "axios";
import { EquipmentMasterBase } from "../shared/models/equipment/equipmentMasterBase.model";
import { API_KEY, API_URL } from "../shared/constants/equipmentsConstants";
import { IEquipmentPaged } from "../shared/models/equipment/pagedEquipment.model";

const createConfig = () => ({
  headers: {
    "x-functions-key": API_KEY,
  },
});

const handleError = (error: unknown, customMessage: string): never => {
  if (axios.isAxiosError(error)) {
    console.error("API Error:", error.response?.data || error.message);
    throw new Error(`${customMessage}: ${error.message}`);
  } else {
    console.error("Unexpected Error:", error);
    throw new Error("Failed to fetch data due to an unexpected error.");
  }
};

export const GetEquipmentsByCartId = async (
  cartId: string
): Promise<EquipmentMasterBase[]> => {
  try {
    const response = await axios.get(
      `${API_URL}/by-cart/${cartId}`,
      createConfig()
    );
    console.log(response);
    return Array.isArray(response.data.data) ? response.data.data : [];
  } catch (error) {
    handleError(error, "Failed to fetch equipment data");
    return [];
  }
};

export const GetEquipmentPartsById = async (
  id: number
): Promise<EquipmentMasterBase[]> => {
  try {
    const response = await axios.get(`${API_URL}/${id}/parts`, createConfig());
    return Array.isArray(response.data.data) ? response.data.data : [];
  } catch (error) {
    handleError(error, "Failed to fetch equipment parts data");
    return [];
  }
};

export const GetEquipmentHierarchy = async (
  id: number,
  includeParts: boolean
): Promise<EquipmentMasterBase[]> => {
  try {
    try {
      const response = await axios.get(
        `${API_URL}/${id}/hierarchy?includeParts=${includeParts}`,
        createConfig()
      );
      return Array.isArray(response.data.data) ? response.data.data : [];
    } catch (error) {
      handleError(error, "Failed to fetch equipment data");
      return [];
    }
  } catch (error) {
    handleError(error, "Failed to fetch equipment data");
    return [];
  }
};

export const GetEquipmentChildren = async (
  id: number
): Promise<EquipmentMasterBase[]> => {
  try {
    try {
      const response = await axios.get(
        `${API_URL}/${id}/children`,
        createConfig()
      );
      console.log(response);
      return Array.isArray(response.data.data) ? response.data.data : [];
    } catch (error) {
      handleError(error, "Failed to fetch equipment data");
      return [];
    }
  } catch (error) {
    handleError(error, "Failed to fetch equipment data");
    return [];
  }
};

export const GetPagedEquipments = async (
  page: number,
  search?: string
): Promise<IEquipmentPaged> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      pageSize: "9",
    });

    if (search) {
      params.append("search", search);
    }

    const response = await axios.get(
      `${API_URL}?${params.toString()}`,
      createConfig()
    );

    return (
      response.data ||
      ({
        data: [],
        currentPage: page,
        totalPages: 0,
        perPage: 0,
        totalItems: 0,
        statusCode: 0,
      } as IEquipmentPaged)
    );
  } catch (error) {
    handleError(error, "Failed to fetch equipments data");
    return {
      data: [],
      currentPage: page,
      totalPages: 0,
      perPage: 0,
      totalItems: 0,
      statusCode: 0,
    };
  }
};
