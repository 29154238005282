import axios from "axios";
import { API_KEY, API_URL } from "../shared/constants/usersConstants";
import { IContext } from "../shared/models/context/user.context.model";

const createConfig = () => ({
    headers: {
        'x-functions-key': API_KEY,
    },
});

const handleError = (error: unknown, customMessage: string) => {
    if (axios.isAxiosError(error)) {
        throw new Error(`${customMessage}: ${error.message}`);
    } else {
        throw new Error('Failed to verify user due to an unexpected error.');
    }
};

export const VerifyIfUserExists = async (context: IContext): Promise<void> => {
    if (!context) {
        throw new Error('Context is not available');
    }

    try {
        const url = `${API_URL}?userId=${context.user.homeAccountId}&userName=${context.user.name}`;
        await axios.get(url, createConfig());
    } catch (error) {
        handleError(error, 'Failed to verify if user exists');
    }
};
