import React, { useState } from "react";
import { Box, Button, Typography, Skeleton } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view";
import { EquipmentMasterBase } from "../../../models/equipment/equipmentMasterBase.model";
import { Delete, CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import EquipmentTreeItem from "./equipmentTreeItem.component";

interface EquipmentTreeViewProps {
  data: EquipmentMasterBase[];
  onRemove: (equipmentIds: number[]) => void;
  loading: boolean;
  isAdding?: boolean;
}

const EquipmentTreeView: React.FC<EquipmentTreeViewProps> = ({
  data,
  onRemove,
  loading,
  isAdding = false,
}) => {
  const [selected, setSelected] = useState<number[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);

  const collectChildIds = (items: EquipmentMasterBase[]): number[] =>
    items.reduce<number[]>((ids, item) => {
      ids.push(item.equipmentId);
      if (item.children) ids.push(...collectChildIds(item.children));
      return ids;
    }, []);

  const totalEquipments = data.reduce(
    (total, item) =>
      total + 1 + (item.children ? collectChildIds(item.children).length : 0),
    0
  );

  const findParent = (
    rootItems: EquipmentMasterBase[],
    childId: number
  ): EquipmentMasterBase | null => {
    for (const item of rootItems) {
      if (item.children?.some((child) => child.equipmentId === childId)) {
        return item;
      }
      const parent = findParent(item.children || [], childId);
      if (parent) return parent;
    }
    return null;
  };

  const toggleSelectEquipment = (
    equipmentId: number,
    children: EquipmentMasterBase[] = []
  ) => {
    const idsToToggle = [equipmentId, ...collectChildIds(children)];
    const selectOrDeselect = (
      prevSelected: number[],
      idsToToggle: number[]
    ) => {
      const isSelected = prevSelected.includes(equipmentId);
      if (isSelected) {
        const updatedSelection = prevSelected.filter(
          (id) => !idsToToggle.includes(id)
        );
        return validateParentDeselection(updatedSelection, equipmentId);
      } else {
        const updatedSelection = [
          ...new Set([...prevSelected, ...idsToToggle]),
        ];
        return updateParentSelection(updatedSelection, equipmentId);
      }
    };

    const updateParentSelection = (
      selectedIds: number[],
      currentId: number
    ): number[] => {
      const parent = findParent(data, currentId);
      if (!parent) return selectedIds;

      const allChildrenSelected = parent.children.every((child) =>
        selectedIds.includes(child.equipmentId)
      );

      if (allChildrenSelected && !selectedIds.includes(parent.equipmentId)) {
        selectedIds = [...selectedIds, parent.equipmentId];
        return updateParentSelection(selectedIds, parent.equipmentId);
      }
      return selectedIds;
    };

    const validateParentDeselection = (
      selectedIds: number[],
      currentId: number
    ): number[] => {
      const parent = findParent(data, currentId);
      if (!parent) return selectedIds;

      const allChildrenSelected = parent.children.every((child) =>
        selectedIds.includes(child.equipmentId)
      );

      if (!allChildrenSelected && selectedIds.includes(parent.equipmentId)) {
        selectedIds = selectedIds.filter((id) => id !== parent.equipmentId);
        return validateParentDeselection(selectedIds, parent.equipmentId);
      }
      return selectedIds;
    };

    setSelected((prevSelected) => selectOrDeselect(prevSelected, idsToToggle));
  };

  const handleRemoveSelected = () => {
    const idsToRemove = selected.flatMap((id) => {
      const item = data.find((equipment) => equipment.equipmentId === id);
      return item ? [id, ...collectChildIds(item.children || [])] : [id];
    });
    onRemove(idsToRemove);
    setSelected((prevSelected) =>
      prevSelected.filter((id) => !idsToRemove.includes(id))
    );
  };

  const handleSelectAllToggle = () => {
    const allIds = data.flatMap((item) => [
      item.equipmentId,
      ...collectChildIds(item.children || []),
    ]);

    setSelected((prevSelected) =>
      prevSelected.length === allIds.length ? [] : allIds
    );
  };

  const toggleExpand = (itemId: string) => {
    setExpanded((prevExpanded) =>
      prevExpanded.includes(itemId)
        ? prevExpanded.filter((id) => id !== itemId)
        : [...prevExpanded, itemId]
    );
  };

  const handleItemClick = (
    e: React.MouseEvent<Element, MouseEvent>,
    itemId: number
  ) => {
    if (
      e.target instanceof Element &&
      e.target.closest(".MuiTreeItem-iconContainer")
    ) {
      toggleExpand(String(itemId));
    }
  };

  return (
    <Box sx={{ backgroundColor: "#eef2f5", padding: 2 }}>
      {loading ? (
        <Box>
          {[...Array(2)].map((_, index) => (
            <Box key={index} sx={{ margin: 3 }}>
              <Skeleton
                variant="rectangular"
                height={25}
                width="30%"
                sx={{ marginBottom: 1, backgroundColor: "#D3D3D3" }}
              />
              <Skeleton
                variant="rectangular"
                height={12}
                width="15%"
                sx={{ marginBottom: 5, backgroundColor: "#D3D3D3" }}
              />
            </Box>
          ))}
        </Box>
      ) : data.length > 0 ? (
        <>
          <Box display="flex" justifyContent="space-between" marginBottom={1}>
            <Typography
              variant="body2"
              sx={{
                marginRight: 2,
                color: "#000",
                padding: "6px 8px",
                backgroundColor: "#b0bec5",
                display: "inline-block",
              }}
            >
              Total equipments: <b>{totalEquipments}</b>
            </Typography>

            <Box display="flex" gap={1}>
              <Button
                variant="contained"
                size="small"
                onClick={handleSelectAllToggle}
                startIcon={
                  selected.length ===
                  data.flatMap((item) => [
                    item.equipmentId,
                    ...collectChildIds(item.children || []),
                  ]).length ? (
                    <CheckBox />
                  ) : (
                    <CheckBoxOutlineBlank />
                  )
                }
                sx={{
                  backgroundColor: "#b0bec5",
                  color: "#000",
                  "&:hover": { backgroundColor: "#90a4ae" },
                  fontSize: "10px",
                  padding: "4px 8px",
                }}
              >
                {selected.length ===
                data.flatMap((item) => [
                  item.equipmentId,
                  ...collectChildIds(item.children || []),
                ]).length
                  ? "Deselect All"
                  : "Select All"}
              </Button>

              <Button
                variant="contained"
                size="small"
                color="error"
                onClick={handleRemoveSelected}
                disabled={selected.length === 0}
                startIcon={<Delete />}
                sx={{
                  backgroundColor: "#c62828",
                  "&:hover": { backgroundColor: "#b71c1c" },
                  fontSize: "10px",
                  padding: "4px 8px",
                }}
              >
                Remove
              </Button>
            </Box>
          </Box>

          <SimpleTreeView expandedItems={expanded}>
            {data.map((item) => (
              <EquipmentTreeItem
                key={item.equipmentId}
                node={item}
                level={0}
                selected={selected}
                toggleSelectEquipment={toggleSelectEquipment}
                handleItemClick={handleItemClick}
              />
            ))}
          </SimpleTreeView>
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}
        >
          <Typography
            variant="body2"
            color="#333"
            display="flex"
            alignItems="center"
          >
            <InfoIcon sx={{ marginRight: 1, color: "#555" }} />
            {isAdding
              ? "Double-click an equipment to add to the list"
              : "No equipment added yet. Double-click an equipment to add to the list"}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default EquipmentTreeView;
