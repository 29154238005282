import React, { createContext, useContext, useState, ReactNode } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import styled from "@emotion/styled";

interface SnackBarAlert {
  openSnackbar: (
    message: string,
    severity: "success" | "error" | "info"
  ) => void;
  closeSnackbar: () => void;
}

const SnackbarContext = createContext<SnackBarAlert | undefined>(undefined);
const CustomAlert = styled(Alert)(() => ({
  color: "white",
}));

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [snackOpen, setSnackOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error" | "info">();

  const openSnackbar = (
    message: string,
    severity: "success" | "error" | "info"
  ) => {
    setMessage(message);
    setSeverity(severity);
    setSnackOpen(true);
  };

  const closeSnackbar = () => {
    setSnackOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
      {children}
      <Snackbar
        open={snackOpen}
        autoHideDuration={4000}
        onClose={closeSnackbar}
      >
        <CustomAlert
          onClose={closeSnackbar}
          severity={severity}
          variant="filled"
          sx={{
            width: "100%",
          }}
        >
          {message}
        </CustomAlert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export const useSnackbarContext = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error(
      "useSnackbarContext must be used within a SnackbarProvider"
    );
  }
  return context;
};
