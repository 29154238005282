import React, { useEffect, useState } from "react";
import { Box, Button, ThemeProvider, Popover } from "@mui/material";
import { theme } from "../../../styles/theme";
import { Cart } from "../../models/cart/cart.model";
import { GetAllAsync } from "../../../service/cartService";
import { CartsList } from "./list/cartsList.component";
import { useSnackbarContext } from "../styled/alert/snackBarAlert.component";
import { useAppContext } from "../../context/app.context";
import AddNewCartView from "./views/addNewCartView.component";
import EditCartView from "./views/editCartView.component";
import { Add } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import { IDownloadExport } from "../../models/cart/actions/exportCart.model";
import { ExportedCartsDownloadsView } from "./views/exportedCartsDownloadsView.component";
import { GetAllDownloadsAsync } from "../../../service/downloadService";

const CartsView: React.FC = () => {
  const { openSnackbar } = useSnackbarContext();
  const [loading, setLoading] = useState(true);
  const [cartList, setCartList] = useState<Cart[]>([]);
  const [noDataFound, setNoDataFound] = useState(true);
  const [isAdding, setIsAdding] = useState(false);
  const [editingCart, setEditingCart] = useState<Cart | null>(null);
  const [exportAnchorEl, setExportAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const [downloadsList, setDownloadsList] = useState<IDownloadExport[]>([]);
  const [downloadsLoading, setDownloadsLoading] = useState(true);
  const [downloadsNoDataFound, setDownloadsNoDataFound] = useState(false);
  const context = useAppContext();

  const fetchCarts = async () => {
    setLoading(true);
    setNoDataFound(true);
    try {
      const data = await GetAllAsync(context);
      setCartList(data);
      setNoDataFound(data.length === 0);
    } catch {
      openSnackbar("Failed to fetch carts. Please try again later.", "error");
    } finally {
      setLoading(false);
    }
  };

  const fetchDownloads = async () => {
    setDownloadsLoading(true);
    try {
      const data = await GetAllDownloadsAsync(context);
      setDownloadsList(data);
      setDownloadsNoDataFound(data.length === 0);
    } catch (error) {
      console.error("Error fetching downloads data:", error);
      setDownloadsNoDataFound(true);
    } finally {
      setDownloadsLoading(false);
    }
  };

  useEffect(() => {
    fetchCarts();
  }, []);

  const handleDeleteSuccess = (deletedCartId: string) => {
    openSnackbar("Cart deleted successfully!", "success");
    setCartList((prevCartList) => {
      const updatedCartList = prevCartList.filter(
        (cart) => cart.id !== deletedCartId
      );
      setNoDataFound(updatedCartList.length === 0);
      return updatedCartList;
    });
  };

  const handleUpdateSuccess = async () => {
    openSnackbar("Cart updated successfully!", "success");
    await fetchCarts();
  };

  const handleUpdateFailed = () => {
    openSnackbar("Cart update error! Try again later.", "error");
  };

  const handleAddNewCart = () => {
    setIsAdding(true);
  };

  const handleRefreshCarts = async () => {
    setIsAdding(false);
    await fetchCarts();
  };

  const handleEditCart = (cart: Cart) => {
    setEditingCart(cart);
  };

  const handleEditCartBack = () => {
    setEditingCart(null);
    fetchCarts();
  };

  const handleOpenExportedCarts = (event: React.MouseEvent<HTMLElement>) => {
    setExportAnchorEl(event.currentTarget);
    fetchDownloads();
  };

  const handleCloseExportedCarts = () => {
    setExportAnchorEl(null);
  };

  const openExportedCarts = Boolean(exportAnchorEl);
  const exportedCartsPopoverId = openExportedCarts
    ? "exported-carts-popover"
    : undefined;

  return (
    <ThemeProvider theme={theme}>
      {!isAdding && !editingCart ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              width: "98%",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  gap: 1,
                  paddingBottom: 2,
                  marginLeft: "auto",
                }}
              >
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={handleAddNewCart}
                  sx={{ color: "white" }}
                  startIcon={<Add />}
                >
                  Add Cart
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleOpenExportedCarts}
                  startIcon={<DownloadIcon />}
                  sx={{ color: "white" }}
                >
                  Exported Carts
                </Button>
                <Box>
                  {" "}
                  <Popover
                    id={exportedCartsPopoverId}
                    open={openExportedCarts}
                    anchorEl={exportAnchorEl}
                    onClose={handleCloseExportedCarts}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    sx={{ mt: 1 }}
                  >
                    <Box sx={{ padding: 2, width: 380 }}>
                      <ExportedCartsDownloadsView
                        data={downloadsList}
                        loading={downloadsLoading}
                        noDataFound={downloadsNoDataFound}
                      />
                    </Box>
                  </Popover>
                </Box>
              </Box>
              <Box>
                <CartsList
                  data={cartList}
                  loading={loading}
                  noDataFound={noDataFound}
                  onDeleteSuccess={handleDeleteSuccess}
                  onUpdateSuccess={handleUpdateSuccess}
                  onUpdateFailed={handleUpdateFailed}
                  onEditCart={handleEditCart}
                />
              </Box>
            </Box>
          </Box>
        </>
      ) : isAdding ? (
        <AddNewCartView onBack={handleRefreshCarts} />
      ) : (
        editingCart && (
          <EditCartView
            cart={editingCart}
            onBack={handleEditCartBack}
            onUpdateSuccess={handleUpdateSuccess}
            onUpdateFailed={handleUpdateFailed}
          />
        )
      )}
    </ThemeProvider>
  );
};

export default CartsView;
